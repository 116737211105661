declare global {
   interface Window {
      clarity: (event: string, action: string) => void;
   }
}

export function trackClarityEvent(action: string) {
   if (typeof window === 'undefined' || window.clarity === undefined) {
      return;
   }

   window.clarity('event', action);
}
